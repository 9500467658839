// frontend/src/components/DispatchDialog.js

import React, { useState } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button, 
    TextField, 
    Typography 
} from '@mui/material';
import { updateOrderStatusAndTracking } from '../services/api';
import moment from 'moment';

const DispatchDialog = ({ open, onClose, order, onDispatched }) => {
    const [trackingNumber, setTrackingNumber] = useState('');
    // Initialize dispatchedDate with current date and time in 'YYYY-MM-DDTHH:mm' format
    const [dispatchedDate, setDispatchedDate] = useState(moment().format('YYYY-MM-DDTHH:mm'));
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleDispatch = async () => {
        setIsSubmitting(true);
        try {
            // Convert dispatchedDate to proper format if needed
            await updateOrderStatusAndTracking(order.orderId, 'Dispatched', trackingNumber, dispatchedDate);
            onDispatched();
            onClose();
        } catch (err) {
            setError(err.response?.data?.message || 'Error dispatching order.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDateChange = (e) => {
        const selectedDateTime = e.target.value;
        const now = moment();
        const selectedMoment = moment(selectedDateTime, 'YYYY-MM-DDTHH:mm', true);

        if (!selectedMoment.isValid()) {
            setError('Invalid date and time format.');
            return;
        }

        if (selectedMoment.isAfter(now)) {
            setError('Dispatched date and time cannot be in the future.');
        } else {
            setError('');
            setDispatchedDate(selectedDateTime);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Dispatch Order</DialogTitle>
            <DialogContent>
                <Typography>Order Number: {order.external_ref}</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Tracking Number (optional)"
                    type="text"
                    fullWidth
                    value={trackingNumber}
                    onChange={(e) => setTrackingNumber(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Dispatched Date & Time"
                    type="datetime-local"
                    fullWidth
                    value={dispatchedDate}
                    onChange={handleDateChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        max: moment().format('YYYY-MM-DDTHH:mm'), // Prevent future dates and times
                    }}
                />
                {error && <Typography color="error" variant="body2">{error}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={isSubmitting}>Cancel</Button>
                <Button 
                    onClick={handleDispatch} 
                    variant="contained" 
                    color="primary" 
                    disabled={isSubmitting || !!error}
                >
                    {isSubmitting ? 'Dispatching...' : 'Dispatch'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DispatchDialog;
