// frontend/src/components/GrafanaKpi.js

import React, { useState, useEffect, useCallback } from 'react';
import { getGrafanaKpi } from '../services/api';
import Layout from './Layout';
import { Box, Typography, Button, Card, CardContent, Grid, Dialog, Paper } from '@mui/material';

const GrafanaKpi = () => {
    const [imageSrc, setImageSrc] = useState('');
    const [imageSrcTmr, setImageSrcTmr] = useState('');
    const [imageSrcLastHour, setImageSrcLastHour] = useState('');
    const [timestamp, setTimestamp] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogImageSrc, setDialogImageSrc] = useState('');

    const loadImages = useCallback(async () => {
        try {
            const response = await getGrafanaKpi();
            if (response && response.imageSrc && response.imageSrcTmr && response.imageSrcLastHour) {
                const newImageSrc = `${response.imageSrc}?t=${Date.now()}`;
                const newImageSrcTmr = `${response.imageSrcTmr}?t=${Date.now()}`;
                const newImageSrcLastHour = `${response.imageSrcLastHour}?t=${Date.now()}`;
                console.log('Loading new images:', newImageSrc, newImageSrcTmr, newImageSrcLastHour);
                setImageSrc(newImageSrc);
                setImageSrcTmr(newImageSrcTmr);
                setImageSrcLastHour(newImageSrcLastHour);
                setTimestamp(response.timestamp);
            } else {
                console.error('Invalid response data:', response);
            }
        } catch (error) {
            console.error("There was an error loading images!", error);
        }
    }, []);
    
    useEffect(() => {
        loadImages();

        const intervalId = setInterval(loadImages, 60000); // Refresh images every 60 seconds
        return () => clearInterval(intervalId);
    }, [loadImages]);

    useEffect(() => {
        const updateTimestamp = () => {
            const now = new Date();
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            setTimestamp(now.toLocaleDateString('en-US', options));
        };

        const intervalId = setInterval(updateTimestamp, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handleImageClick = (src) => {
        setDialogImageSrc(src);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogImageSrc('');
    };

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                    Grafana KPI Dashboard
                </Typography>
                <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 3 }}>
                    {timestamp}
                </Typography>
                <Button variant="contained" color="primary" onClick={loadImages} fullWidth>
                    Refresh Images
                </Button>
                <Grid container spacing={3} sx={{ marginTop: 3 }}>
                    <Grid item xs={12}>
                        <Paper elevation={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Today
                                    </Typography>
                                    {imageSrc ? (
                                        <Box
                                            component="img"
                                            src={imageSrc}
                                            alt="Grafana KPI Dashboard Screenshot Today"
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                maxHeight: '400px', 
                                                borderRadius: 1,
                                                cursor: 'pointer',
                                                objectFit: 'contain', // Maintain aspect ratio
                                            }}
                                            onClick={() => handleImageClick(imageSrc)}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            No image available for Today
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Tomorrow
                                    </Typography>
                                    {imageSrcTmr ? (
                                        <Box
                                            component="img"
                                            src={imageSrcTmr}
                                            alt="Grafana KPI Dashboard Screenshot Tomorrow"
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                maxHeight: '400px',
                                                borderRadius: 1,
                                                cursor: 'pointer',
                                                objectFit: 'contain', // Maintain aspect ratio
                                            }}
                                            onClick={() => handleImageClick(imageSrcTmr)}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            No image available for Tomorrow
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Operator Performance Statistics (Last Hour)
                                    </Typography>
                                    {imageSrcLastHour ? (
                                        <Box
                                            component="img"
                                            src={imageSrcLastHour}
                                            alt="Grafana KPI Dashboard Operator Performance Statistics Screenshot Last Hour"
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                maxHeight: '400px',
                                                borderRadius: 1,
                                                cursor: 'pointer',
                                                objectFit: 'contain', // Maintain aspect ratio
                                            }}
                                            onClick={() => handleImageClick(imageSrcLastHour)}
                                        />
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            No image available for Operator Performance Statistics (Last Hour)
                                        </Typography>
                                    )}
                                </CardContent>
                            </Card>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>

            {/* Dialog for Enlarged Image */}
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
                <Box
                    component="img"
                    src={dialogImageSrc}
                    alt="Enlarged Grafana KPI Dashboard Screenshot"
                    sx={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: '90vh', 
                        objectFit: 'contain', // Ensure image maintains aspect ratio and doesn't get distorted
                    }}
                />
            </Dialog>
        </Layout>
    );
};

export default GrafanaKpi;
