// frontend/src/components/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiLogin } from '../services/api';
import { AuthContext } from '../contexts/AuthContext';
import {
    Box,
    Card,
    CardContent,
    TextField,
    Button,
    Typography,
    Grid,
    Alert,
    InputAdornment,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Define company colors
const primaryColor = '#E72D88';
const secondaryColor = '#197DC2';

const GradientBackground = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: `linear-gradient(135deg, ${primaryColor}, ${secondaryColor})`,
}));

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 400,
    width: '90%',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: theme.shadows[5],
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
    padding: theme.spacing(1.5),
    backgroundColor: secondaryColor,
    color: '#fff',
    '&:hover': {
        backgroundColor: primaryColor,
    },
}));

const Logo = styled('img')(({ theme }) => ({
    width: '120px',
    marginBottom: theme.spacing(2),
}));

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showError, setShowError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { login } = useContext(AuthContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await apiLogin({ username, password });
            if (response.success) {
                login();
                navigate('/dashboard');
            } else {
                setErrorMessage(response.message);
                setShowError(true);
                hideErrorAfterDelay();
            }
        } catch (error) {
            console.error('Login error:', error);
            setErrorMessage('An error occurred during login. Please try again.');
            setShowError(true);
            hideErrorAfterDelay();
        } finally {
            setLoading(false);
        }
    };

    const hideErrorAfterDelay = () => {
        setTimeout(() => {
            setShowError(false);
        }, 5000);
    };

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <GradientBackground>
            <StyledCard>
                <CardContent>
                    <Grid container spacing={2} direction="column" alignItems="center">
                        <Grid item>
                            <Logo
                                src="/images/LogoPrimaryStacked.png"
                                alt="Logo"
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" align="center" gutterBottom>
                                Welcome to IV Linx
                            </Typography>
                            <Typography variant="h6" align="center" >
                                LOGIN
                            </Typography>
                        </Grid>
                    </Grid>

                    {showError && (
                        <Alert severity="error" sx={{ mt: 2, animation: 'fadeIn 0.5s' }}>
                            {errorMessage}
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Username"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon color="action" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon color="action" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            aria-label="toggle password visibility"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <StyledButton
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={loading}
                            startIcon={loading && <CircularProgress size={20} color="inherit" />}
                        >
                            {loading ? 'Logging in...' : 'Login'}
                        </StyledButton>
                    </form>
                </CardContent>
            </StyledCard>
        </GradientBackground>
    );
};

export default Login;
