// frontend/src/components/ClientTable.js

import React, { useState } from 'react';
import { 
    Box, 
    TableContainer, 
    Paper, 
    Table, 
    TableHead, 
    TableRow, 
    TableCell, 
    TableBody, 
    IconButton, 
    Typography, 
    Button, 
    LinearProgress, 
    Tooltip, 
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    CircularProgress
} from '@mui/material';
import AlarmIcon from '@mui/icons-material/Alarm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning'; // Imported for blinking icon
import ErrorOutline from '@mui/icons-material/ErrorOutline'; // For Issue/Error
import { useTheme, styled } from '@mui/material/styles';
import { getColorByPercentage, getDispatchColor, getChipStyles } from '../utils/colorUtils'; // Adjust path accordingly
import { getReShipOrdersToday } from '../services/api'; // Ensure this API call exists
import IssueDialog from './IssueDialog'; // Ensure IssueDialog component exists and is correctly imported

// Styled component for blinking attention icon
const BlinkingWarningIcon = styled(WarningIcon)(({ theme }) => ({
    color: theme.palette.error.main,
    animation: 'blinker 1s linear infinite',
    '@keyframes blinker': {
        '50%': { opacity: 0 },
    },
    marginLeft: theme.spacing(0.5),
}));


const ClientTable = ({ clients, onDispatchTodayClick, onCutoffTimeClick }) => {
    const theme = useTheme();
    const dispatchPalette = theme.palette.dispatch;

    // State for Re-Ship Orders Modal
    const [reShipModalOpen, setReShipModalOpen] = useState(false);
    const [selectedClientReShipOrders, setSelectedClientReShipOrders] = useState([]);
    const [reShipLoading, setReShipLoading] = useState(false);
    const [reShipError, setReShipError] = useState(null);
    const [selectedClientNameForReShip, setSelectedClientNameForReShip] = useState('');

    // State for Issue Dialog
    const [issueDialogOpen, setIssueDialogOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    // Format DateTime
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date)) return 'Invalid Date';

        const optionsDate = { year: 'numeric', month: 'short', day: 'numeric' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };

        const formattedDate = date.toLocaleDateString(undefined, optionsDate); // e.g., '29 Oct 2024'
        const formattedTime = date.toLocaleTimeString(undefined, optionsTime); // e.g., '22:58:57'

        return `${formattedDate} ${formattedTime}`; // '29 Oct 2024 22:58:57'
    };


    // Handler to open Re-Ship Orders Modal
    const handleReShipOrdersClick = async (clientName) => {
        setSelectedClientNameForReShip(clientName);
        setReShipModalOpen(true);
        setReShipLoading(true);
        setReShipError(null);
        try {
            const response = await getReShipOrdersToday(clientName); // Fetch Re-Ship Orders
            setSelectedClientReShipOrders(response); // Assuming response is an array of orders
        } catch (error) {
            console.error(`Error fetching Re-Ship Orders for ${clientName}:`, error);
            setReShipError('Failed to load Re-Ship Orders. Please try again.');
        } finally {
            setReShipLoading(false);
        }
    };

    // Handler to close Re-Ship Orders Modal
    const handleReShipModalClose = () => {
        setReShipModalOpen(false);
        setSelectedClientReShipOrders([]);
        setSelectedClientNameForReShip('');
        setReShipError(null);
    };

    // Handler to open Issue Dialog
    const handleAddIssueClick = (order) => {
        setSelectedOrder(order);
        setIssueDialogOpen(true);
    };

    // Handler to close Issue Dialog
    const handleIssueDialogClose = () => {
        setIssueDialogOpen(false);
        setSelectedOrder(null);
    };

    // Handler after issue is added or updated
    const handleIssueAdded = () => {
        // Implement any refresh logic if necessary
        // For example, you might want to refresh the Re-Ship Orders list
        // You can refetch the Re-Ship Orders or update the state accordingly
        // Here, we'll simply close the dialog
        setIssueDialogOpen(false);
        setSelectedOrder(null);
        // Optionally, refetch Re-Ship Orders
        if (selectedClientNameForReShip) {
            handleReShipOrdersClick(selectedClientNameForReShip);
        }
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* Cutoff Times Icon */}
                            <TableCell>
                                <Tooltip title="Cutoff Times">
                                    <AlarmIcon />
                                </Tooltip>
                            </TableCell>
                            {/* Client Name */}
                            <TableCell>Client Name</TableCell>
                            {/* Total New Orders Today */}
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                Total New Orders (Today)
                            </TableCell>
                            {/* Required Dispatch Today */}
                            <TableCell>Required Dispatch Today</TableCell>
                            {/* Re-Ship Orders Today */}
                            <TableCell>Re-Ship Orders Today</TableCell> {/* New Column */}
                            {/* Dispatched Today */}
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                Dispatched Today
                            </TableCell>
                            {/* Cancelled Orders Today */}
                            <TableCell>Cancelled Orders Today</TableCell>
                            {/* Fulfilment Accuracy Rate */}
                            <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                Fulfilment Accuracy Rate
                            </TableCell>
                            {/* Progress Percentage */}
                            <TableCell>Progress (%)</TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {clients.map((client) => {
                            // Calculate displayProgress: 100 if both requiredDispatchToday and dispatchedOrders are 0
                            
                            const displayProgress =
                                (client.requiredDispatchToday === 0 && client.dispatchedOrders === 0 && client.reshipOrdersToday === 0)
                                    ? 100
                                    : (client.progressPercentage > 100 ? 100 : client.progressPercentage || 0);

                            const dynamicColor = getColorByPercentage(displayProgress, dispatchPalette);
                            const dispatchColor = getDispatchColor(client.requiredDispatchToday, dispatchPalette);
                            const reshipColor = getDispatchColor(client.reshipOrdersToday, dispatchPalette);
                            return (
                                <TableRow key={client.clientName}>
                                    {/* Cutoff Times Icon/Button */}
                                    <TableCell>
                                        <IconButton
                                            size="small"
                                            onClick={(e) => onCutoffTimeClick(e, client)}
                                            aria-label={`View cutoff times for ${client.clientName}`}
                                        >
                                            <AlarmIcon color="action" />
                                        </IconButton>
                                    </TableCell>
                                    {/* Client Name */}
                                    <TableCell>{client.clientName}</TableCell>
                                    {/* Total New Orders Today */}
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                        {client.totalNewOrders || 0}
                                    </TableCell>
                                    {/* Required Dispatch Today */}
                                    <TableCell>
                                        <Button
                                            onClick={() => onDispatchTodayClick(client.clientName)}
                                            sx={{
                                                textTransform: 'none',
                                                fontWeight: 'bold',
                                                padding: 0,
                                                minWidth: 'auto',
                                                display: 'flex',
                                                alignItems: 'center',
                                                '&:hover .info-icon': {
                                                    color: dispatchColor,
                                                },
                                            }}
                                            aria-label={`View Required Dispatch Today Orders for ${client.clientName}`}
                                        >
                                            <Typography 
                                                sx={{ 
                                                    color: dispatchColor, 
                                                    fontWeight: 'bold' 
                                                }}
                                            >
                                                {client.requiredDispatchToday || 0}
                                            </Typography>
                                            <InfoOutlinedIcon
                                                fontSize="small"
                                                className="info-icon"
                                                sx={{ color: 'text.secondary' }}
                                            />
                                        </Button>
                                    </TableCell>
                                    {/* Re-Ship Orders Today */}
                                    <TableCell>
                                        {client.reshipOrdersToday > 0 ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Typography 
                                                    variant="body2" 
                                                    sx={{ 
                                                        fontWeight: 'bold',
                                                        color: reshipColor,
                                                    }}
                                                    aria-label={`Re-Ship Orders Today: ${client.reshipOrdersToday}`}
                                                >
                                                    {client.reshipOrdersToday}
                                                </Typography>
                                                <BlinkingWarningIcon aria-label={`Re-Ship Orders Today: ${client.reshipOrdersToday}`} />
                                                <Button
                                                    variant="text"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => handleReShipOrdersClick(client.clientName)}
                                                    sx={{ ml: 1 }}
                                                    aria-label={`View Re-Ship Orders Today for ${client.clientName}`}
                                                >
                                                    View
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                                0
                                            </Typography>
                                        )}
                                    </TableCell>
                                    {/* Dispatched Today */}
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                        <Typography sx={{ color: dispatchPalette.green, fontWeight: 'bold' }}>
                                            {client.dispatchedOrders || 0}
                                        </Typography>
                                    </TableCell>
                                    {/* Cancelled Orders Today */}
                                    <TableCell 
                                        sx={{ 
                                            color: client.cancelledOrdersToday > 0 
                                                ? dispatchPalette.red 
                                                : dispatchPalette.green, 
                                            fontWeight: 'bold' 
                                        }}
                                    >
                                        {client.cancelledOrdersToday || 0}
                                    </TableCell>
                                    {/* Fulfilment Accuracy Rate */}
                                    <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: client.fulfilmentAccuracyRate >= 90
                                                    ? 'success.main'
                                                    : client.fulfilmentAccuracyRate >= 75
                                                        ? 'warning.main'
                                                        : 'error.main',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {client.fulfilmentAccuracyRate
                                                ? `${client.fulfilmentAccuracyRate.toFixed(2)}%`
                                                : '0.00%'}
                                        </Typography>
                                    </TableCell>
                                    {/* Progress Percentage */}
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <Typography 
                                                variant="body2" 
                                                sx={{ 
                                                    fontWeight: 'bold', 
                                                    mr: 1, 
                                                    fontSize: { xs: '0.75rem', sm: '0.875rem' } 
                                                }}
                                            >
                                                {displayProgress}%
                                            </Typography>
                                            <LinearProgress
                                                variant="determinate"
                                                value={displayProgress}
                                                sx={{
                                                    flexGrow: 1,
                                                    height: 8,
                                                    borderRadius: 5,
                                                    [`& .MuiLinearProgress-bar`]: {
                                                        backgroundColor: dynamicColor,
                                                        transition: 'background-color 0.3s ease',
                                                    },
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                            />
                                        </Box>
                                    </TableCell>

                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Re-Ship Orders Modal */}
            <Dialog
                open={reShipModalOpen}
                onClose={handleReShipModalClose}
                fullWidth
                maxWidth="lg"
                aria-labelledby="re-ship-orders-dialog-title"
                fullScreen={false} // Adjust based on preference
            >
                <DialogTitle id="re-ship-orders-dialog-title">
                    Required Re-Ship Orders Today for {selectedClientNameForReShip}
                </DialogTitle>
                <DialogContent dividers>
                    {reShipLoading ? (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '200px',
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : reShipError ? (
                        <Typography variant="body1" color="error">
                            {reShipError}
                        </Typography>
                    ) : selectedClientReShipOrders.length > 0 ? (
                        <TableContainer component={Paper} sx={{ overflowX: 'auto' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Order Number</TableCell>
                                        <TableCell>Create Date</TableCell>
                                        <TableCell>Shipping Carrier</TableCell>
                                        <TableCell>Shipping Method</TableCell>
                                        <TableCell>Issue Status</TableCell>
                                        <TableCell>Issue/Error</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedClientReShipOrders.map((order) => (
                                        <TableRow key={order.orderId}>
                                            <TableCell>{order.orderNumber}</TableCell>
                                            <TableCell>
                                                {formatDateTime(order.createdAt)}
                                            </TableCell>
                                            <TableCell>{order.shipping_carrier}</TableCell>
                                            <TableCell>{order.shipping_method}</TableCell>
                                            <TableCell>
                                                {order.issueStatus ? (
                                                    <Chip
                                                        label={order.issueStatus}
                                                        sx={{
                                                            backgroundColor: getChipStyles(order.issueStatus),
                                                            color: '#fff',
                                                        }}
                                                    />
                                                ) : null}
                                            </TableCell>
                                            <TableCell>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => handleAddIssueClick(order)}
                                                    aria-label="Add Issue"
                                                >
                                                    <ErrorOutline />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <Typography variant="body1">
                            No Re-Ship Orders Today found for this client.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleReShipModalClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Issue Dialog */}
            {selectedOrder && (
                <IssueDialog
                    open={issueDialogOpen}
                    onClose={handleIssueDialogClose}
                    order={selectedOrder}
                    onIssueAdded={handleIssueAdded}
                />
            )}
        </>
    );

};

export default ClientTable;
