// frontend/src/components/Orders.js

import React, { useState, useEffect } from 'react';
import { 
    Box, 
    Typography, 
    Button, 
    TextField, 
    MenuItem, 
    Checkbox, 
    FormControlLabel, 
    Chip, 
    Tooltip, 
    Paper 
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
import { ErrorOutline, LocalShipping } from '@mui/icons-material';
import { getClientsOrders, getClientsName, getIssueStatuses, updateOrderStatusAndTracking } from '../services/api';
import Layout from './Layout';
import moment from 'moment';
import IssueDialog from './IssueDialog';
import DispatchDialog from './DispatchDialog'; // Import the DispatchDialog

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [clients, setClients] = useState([]);
    const [client, setClient] = useState('ALL');
    const [status, setStatus] = useState('ALL');
    const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
    const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
    const [useCutoff, setUseCutoff] = useState(false);
    const [openIssueDialog, setOpenIssueDialog] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [issueStatus, setIssueStatus] = useState('ALL');
    const [issueStatuses, setIssueStatuses] = useState([]);
    const [openDispatchDialog, setOpenDispatchDialog] = useState(false);
    const [dispatchOrder, setDispatchOrder] = useState(null);

    // Fetch clients on component mount
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await getClientsName();
                if (response.success) {
                    setClients(response.clients);
                } else {
                    console.error('Failed to fetch clients:', response.message);
                }
            } catch (error) {
                console.error('Error fetching clients:', error);
            }
        };
        fetchClients();
    }, []);

    // Fetch issue statuses on component mount
    useEffect(() => {
        const fetchIssueStatuses = async () => {
            try {
                const response = await getIssueStatuses();
                if (response.success) {
                    setIssueStatuses(response.statuses);
                } else {
                    console.error('Failed to fetch issue statuses:', response.message);
                }
            } catch (error) {
                console.error('Error fetching issue statuses:', error);
            }
        };
        fetchIssueStatuses();
    }, []);

    // Load orders based on filters
    const loadOrders = async () => {
        const filters = { client, status, dateFrom, dateTo, useCutoff, issueStatus };
        try {
            const response = await getClientsOrders(filters);
            if (response.success) {
                setOrders(response.orders);
            } else {
                console.error('Failed to fetch orders:', response.message);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    // Load orders on initial render
    useEffect(() => {
        loadOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  // Empty dependency array to run once on mount

    const handleSearch = () => {
        loadOrders();
    };

    // Validation to ensure dateFrom is not greater than dateTo
    const handleDateFromChange = (e) => {
        const selectedFromDate = e.target.value;
        if (moment(selectedFromDate).isAfter(dateTo)) {
            alert("From Date cannot be greater than To Date");
            return; // Do not update the state
        }
        setDateFrom(selectedFromDate);
    };

    const handleDateToChange = (e) => {
        const selectedToDate = e.target.value;
        if (moment(selectedToDate).isBefore(dateFrom)) {
            alert("To Date cannot be earlier than From Date");
            return; // Do not update the state
        }
        setDateTo(selectedToDate);
    };

    // Disable status and date range when "Required Dispatch Today Orders" is checked
    const handleCutoffChange = (e) => {
        setUseCutoff(e.target.checked);
        if (e.target.checked) {
            setStatus('ALL'); // Reset status to 'ALL' when the checkbox is checked
            setIssueStatus('ALL'); // Reset issueStatus to 'ALL' as well
        }
    };

    const handleAddIssueClick = (order) => {
        setSelectedOrder(order);
        setOpenIssueDialog(true);
    };

    const handleIssueDialogClose = () => {
        setOpenIssueDialog(false);
        setSelectedOrder(null);
    };

    const handleIssueAdded = () => {
        // Refresh the orders list or update the order in state
        loadOrders();
    };

    const handleDispatchClick = (order) => {
        setDispatchOrder(order);
        setOpenDispatchDialog(true);
    };

    const handleDispatchDialogClose = () => {
        setOpenDispatchDialog(false);
        setDispatchOrder(null);
    };

    const handleOrderDispatched = () => {
        // Refresh the orders list or update the specific order
        loadOrders();
    };

    const getChipStyles = (status) => {
        switch (status) {
            case 'Error':
                return {
                    backgroundColor: '#dc3545', // Red
                    color: '#fff',
                };
            case 'Resolved':
                return {
                    backgroundColor: '#4caf50', // Green
                    color: '#fff',
                };
            case 'In Progress':
                return {
                    backgroundColor: '#ff9800', // Orange
                    color: '#fff',
                };
            case 'On Hold':
                return {
                    backgroundColor: '#9c27b0', // Purple
                    color: '#fff',
                };
            case 'Urgent':
                return {
                    backgroundColor: '#F27360', // Custom color
                    color: '#fff',
                };
            default:
                return {
                    backgroundColor: '#e0e0e0', // Grey for unknown statuses
                    color: '#000',
                };
        }
    };

    const getOrderStatusChipStyles = (status) => {
        switch (status) {
            case 'New':
                return {
                    backgroundColor: '#2196f3', // Blue
                    color: '#fff',
                };
            case 'Dispatched':
                return {
                    backgroundColor: '#4caf50', // Green
                    color: '#fff',
                };
            case 'Cancelled':
                return {
                    backgroundColor: '#dc3545', // Red
                    color: '#fff',
                };
            default:
                return {
                    backgroundColor: '#9e9e9e', // Grey for unknown statuses
                    color: '#fff',
                };
        }
    };

    const columns = [
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 150,
            getActions: (params) => {
                const actions = [
                    <GridActionsCellItem
                        icon={<ErrorOutline />}
                        label="Add Issue"
                        onClick={() => handleAddIssueClick(params.row)}
                        showInMenu
                    />,
                ];

                if (params.row.orderStatus === 'New') { // Only show Dispatch for 'New' orders
                    actions.push(
                        <GridActionsCellItem
                            icon={<LocalShipping />}
                            label="Dispatch Order"
                            onClick={() => handleDispatchClick(params.row)}
                            showInMenu
                        />
                    );
                }

                return actions;
            },
        },
        { field: 'external_ref', headerName: 'Order Number', width: 140 },
        { field: 'clientName', headerName: 'Client Name', width: 150 },
        {
            field: 'orderStatus',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => (
                <Chip
                    label={params.value}
                    sx={{
                        ...getOrderStatusChipStyles(params.value),
                    }}
                />
            ),
        },
        {
            field: 'issueStatus',
            headerName: 'Issue Status',
            width: 120,
            renderCell: (params) =>
                params.value ? (
                    <Chip
                        label={params.value}
                        sx={{
                            ...getChipStyles(params.value),
                        }}
                    />
                ) : null,
        },
        { field: 'createdAt', headerName: 'Created At', width: 170 },
        { field: 'fulfilmentChannel', headerName: 'Channel', width: 100 },
        { field: 'shipping_carrier', headerName: 'Shipping Carrier', width: 150 },
        { field: 'shipping_method', headerName: 'Shipping Method', width: 170 },
        { field: 'tracking', headerName: 'Tracking Number', width: 160 },
        { field: 'dispatchedDate', headerName: 'Dispatched Date & Time', width: 200 },
    ];

    return (
        <Layout>
            <Box sx={{ padding: 3 }}>
                <Box sx={{ mb: 3 }}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Client's Orders
                    </Typography>
                </Box>
                
                {/* Wrap Filters with Paper */}
                <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
                    {/* Filters */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                            <TextField
                                id="client"
                                label="Client"
                                value={client}
                                onChange={(e) => setClient(e.target.value)}
                                fullWidth
                                select
                            >
                                <MenuItem value="ALL">ALL</MenuItem>
                                {clients.map((client) => (
                                    <MenuItem key={client.clientName} value={client.clientName}>
                                        {client.clientName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                            <TextField
                                id="status"
                                label="Status"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                fullWidth
                                select
                                disabled={useCutoff} // Disable when "Required Dispatch Today Orders" is checked
                            >
                                <MenuItem value="ALL">ALL</MenuItem>
                                <MenuItem value="New">New</MenuItem>
                                <MenuItem value="Dispatched">Dispatched</MenuItem>
                                <MenuItem value="Cancelled">Cancelled</MenuItem>
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                            <TextField
                                id="issue-status"
                                label="Issue Status"
                                value={issueStatus}
                                onChange={(e) => setIssueStatus(e.target.value)}
                                fullWidth
                                select
                                disabled={useCutoff} // Disable when "Required Dispatch Today Orders" is checked
                            >
                                <MenuItem value="ALL">ALL</MenuItem>
                                {issueStatuses.map((status) => (
                                    <MenuItem key={status.statusId} value={status.statusName}>
                                        {status.statusName}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                            <TextField
                                id="from-date"
                                label="From Date"
                                type="date"
                                value={dateFrom}
                                onChange={handleDateFromChange} // Apply validation logic here
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={useCutoff} // Disable date range when cutoff is checked
                            />
                        </Grid>

                        <Grid item xs={12} sm={3} sx={{ minWidth: 120 }}>
                            <TextField
                                id="to-date"
                                label="To Date"
                                type="date"
                                value={dateTo}
                                onChange={handleDateToChange} // Apply validation logic here
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={useCutoff} // Disable date range when cutoff is checked
                            />
                        </Grid>

                        <Grid item xs={12}>
                            {/* Add Checkbox for Required Dispatch Today Orders */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useCutoff}
                                        onChange={handleCutoffChange} // Apply status disabling logic here
                                    />
                                }
                                label="Required Dispatch Today"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button variant="contained" onClick={handleSearch}>
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Data Grid Wrapped with Paper */}
                <Paper elevation={3} sx={{ p: 2 }}>
                    <Box sx={{ height: 600, width: '100%' }}>
                        <DataGrid
                            className="whiteTable"
                            rows={orders}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            getRowId={(row) => row.orderId}
                            sx={{
                                '& .MuiDataGrid-root': {
                                    backgroundColor: '#fff',
                                },
                                '& .MuiDataGrid-cell': {
                                    color: '#555',
                                },
                                '& .MuiDataGrid-columnHeaders': {
                                    backgroundColor: '#fafafa',
                                    color: '#333',
                                },
                                '& .MuiDataGrid-virtualScroller': {
                                    backgroundColor: '#fff',
                                },
                                '& .MuiDataGrid-footerContainer': {
                                    backgroundColor: '#fafafa',
                                },
                            }}
                        />
                    </Box>
                </Paper>

                {/* Issue Dialog */}
                {selectedOrder && (
                    <IssueDialog
                        open={openIssueDialog}
                        onClose={handleIssueDialogClose}
                        order={selectedOrder}
                        onIssueAdded={handleIssueAdded}
                    />
                )}

                {/* Dispatch Dialog */}
                {dispatchOrder && (
                    <DispatchDialog
                        open={openDispatchDialog}
                        onClose={handleDispatchDialogClose}
                        order={dispatchOrder}
                        onDispatched={handleOrderDispatched}
                    />
                )}
            </Box>
        </Layout>
    );

};

export default Orders;
