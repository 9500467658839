// frontend/src/components/Cards/RequiredDispatchProgressCard.js

import React from 'react';
import { Paper, Box, Typography, Tooltip, Chip, LinearProgress, IconButton } from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning'; // Imported for blinking icon
import { useTheme, styled } from '@mui/material/styles';
import { getColorByPercentage } from '../../utils/colorUtils';

// Styled component for blinking attention icon
const BlinkingWarningIcon = styled(WarningIcon)(({ theme }) => ({
    color: theme.palette.error.main,
    animation: 'blinker 1s linear infinite',
    '@keyframes blinker': {
        '50%': { opacity: 0 },
    },
    marginLeft: theme.spacing(0.5),
}));

const RequiredDispatchProgressCard = ({
    icon: Icon,
    label,
    requiredDispatch,
    reshipOrders, // New prop
    progressPercentage,
    tooltip,
    badge,
    sx,
}) => {
    const theme = useTheme();
    const dispatchPalette = theme.palette.dispatch;
    const dynamicColor = getColorByPercentage(progressPercentage, dispatchPalette);

    return (
        <Paper
            elevation={3}
            sx={{
                p: 3,
                borderRadius: 2,
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                ...sx,
            }}
        >
            {/* Header */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: { xs: 40, sm: 56 },
                            width: { xs: 40, sm: 56 },
                            backgroundColor: dynamicColor,
                            color: '#fff',
                            borderRadius: '50%',
                            mr: 2,
                            transition: 'background-color 0.3s ease',
                        }}
                    >
                        <Icon fontSize="large" />
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant={{ xs: 'h5', sm: 'h5' }} sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
                                {label}
                            </Typography>
                            {badge && (
                                <Chip
                                    label={badge}
                                    color="primary"
                                    size="small"
                                    sx={{ ml: 1 }}
                                    aria-label={`${badge} badge`}
                                />
                            )}
                            {tooltip && (
                                <Tooltip title={tooltip} arrow>
                                    <IconButton size="small" sx={{ ml: 0.5 }}>
                                        <InfoOutlinedIcon fontSize="small" color="action" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                        <Typography
                            variant='h4'
                            sx={{
                                fontWeight: 'bold',
                                color: dynamicColor,
                                transition: 'color 0.3s ease',
                            }}
                        >
                            {requiredDispatch}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Re-Ship Orders Today */}
            {reshipOrders > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                    <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 'bold',
                            color: theme.palette.error.main,
                        }}
                        aria-label={`Re-Ship Orders Today: ${reshipOrders}`}
                    >
                        Required Re-Ship Orders Today: {reshipOrders}
                    </Typography>
                    <BlinkingWarningIcon aria-label={`Re-Ship Orders Today: ${reshipOrders}`} />
                </Box>
            )}

            {/* Progress Bar */}
            <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    Progress
                </Typography>
                <LinearProgress
                    variant="determinate"
                    value={progressPercentage > 100 ? 100 : progressPercentage}
                    sx={{
                        height: 10,
                        borderRadius: 5,
                        [`& .MuiLinearProgress-bar`]: {
                            backgroundColor: dynamicColor,
                            transition: 'background-color 0.3s ease',
                        },
                        transition: 'background-color 0.3s ease',
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{
                        mt: 1,
                        fontWeight: 'bold',
                        color: dynamicColor,
                        transition: 'color 0.3s ease',
                        fontSize: { xs: '0.875rem', sm: '0.875rem' }, // 14px
                    }}
                >
                    {progressPercentage > 100 ? 100 : progressPercentage}%
                </Typography>
            </Box>
        </Paper>
    );

};

export default RequiredDispatchProgressCard;
